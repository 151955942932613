import React, { useCallback, useContext, useMemo } from 'react'
import EntitiesSelector from './EntitiesSelector'
import { SchoolContext } from '../../../Providers/SchoolProvider'

/**
 * @param {object} params
 * @param {Array} params.values
 * @param {(any) => any} params.onChange
 * @param {(any) => boolean} [params.filter] 
 * @param {object} [params.style]
 * @returns {JSX.Element}
 */
const SchoolSectionsSelector = ({ values, onChange, filter, style = {} }) => {
  const { schoolSections } = useContext(SchoolContext)

  const mapToOptions = useCallback(section => {
    const { schoolYearName, sectionAbbreviation, sectionName } = section.sectionToYear
    
    const suffix = ` (${schoolYearName})`

    return {
      label: sectionAbbreviation + suffix,
      value: section.id,
      tooltip: sectionName + suffix
    }
  }, [])

  const sectionOptions = useMemo(() => typeof filter === "function"
    ? schoolSections.filter(filter).map(mapToOptions)
    : schoolSections.map(mapToOptions)    
  , [schoolSections, filter])
  
  return (
    <EntitiesSelector
      values={values}
      onChange={onChange}
      entityOptions={sectionOptions}
      style={{ ...style }}
    />
  )
}

export default SchoolSectionsSelector
