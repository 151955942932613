import React, { useMemo, useCallback, useEffect, useState } from 'react'

import { connect } from '../../../reducers/Dispatchers'
import { faGraduationCap, faHospital, faSchool, faStethoscope } from '@fortawesome/pro-solid-svg-icons'
import DataTable from '../../../Components/shared/DataTable/DataTable'
import { INSTITUTION_CONTEXT, SCHOOL_CONTEXT } from '../../../utils/constants'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { fetchSchoolSectionsLinkedToContactPersons } from '../../../utils/api/contactPersons'
import { toFirstUpperCase } from '../../../utils'
import VisibilityValues from './VisibilityValues'
import SectorsSelector from '../../../Components/shared/InternshipsManager/SectorsSelector'
import SchoolSectionsSelector from '../../../Components/shared/InternshipsManager/SchoolSectionsSelector'
import { getSchools } from '../../../reducers/SchoolsReducer'
import { Action } from '../../../Components/shared/DataTable/utils/actions'
import ContactPersonAssignments from './ContactPersonAssignments'
import { Filter } from '../../../Components/shared/DataTable/utils/tableFilter'
import ComponentWithIconLabel from '../../../Components/icons/ComponentWithIconLabel'

import '../../../assets/contact-persons-view.scss'

const SCHOOL_ENTITIES = ['school', 'section']
const INSTITUTION_ENTITIES = ['institution', 'sector']

const CONTEXT_COMMON_COLUMN_TITLES = {
  [VisibilityValues.EXTERNAL]: ['lastname', 'firstname', 'role', 'email', 'phone_number'],
  [VisibilityValues.INTERNAL]: ['lastname', 'firstname', 'email', 'phone_number']
}

const EXTERNAL_VISIBILITY_COLUMN_TITLES = {
  [SCHOOL_CONTEXT]: INSTITUTION_ENTITIES,
  [INSTITUTION_CONTEXT]: SCHOOL_ENTITIES
}

const TRANSLATE_PREFIX_USER = 'user.property.'
const TRANSLATE_PREFIX_CONTEXT = 'context.property.'
const TRANSLATE_NESTED = {
  [SCHOOL_CONTEXT]: 'common.sections',
  [INSTITUTION_CONTEXT]: 'common.sectors'
}

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser),
  schools: getSchools(state.getSchools)
})

/**
 * @param {object} params
 * @param {VisibilityValues} params.visibility
 * @param {TableParameters} params.tableParameters
 * @param {array} params.data
 * @param {Metadata} params.metadata
 * @param {boolean} params.loading
 * @param {(param:TableParameters) => any} params.onTableParametersChange
 * @param {array} params.options
 * @param {(options:array) => any} params.onOptionsChange
 * @returns {React.JSX}
 */
const ContactPersonsTable = ({
  t, user, schools,
  visibility,
  data, metadata, loading,
  tableParameters, onTableParametersChange,
  options, onOptionsChange
}) => {
  const [filteredSchoolSections, setFilteredSchoolSections] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)

  useEffect(() => {
    if (user.context === SCHOOL_CONTEXT && schools[0]?.id) {
      const schoolId = schools[0].id
      fetchSchoolSectionsLinkedToContactPersons(user, schoolId)
        .then(response => setFilteredSchoolSections(response.data[schoolId]))
    }
  }, [user.context, schools[0]])

  const optionsFilter = useCallback(optionToFilter => {
    let filter

    if (user.context === INSTITUTION_CONTEXT) {
      filter = !optionToFilter.archived && optionToFilter?.contactPersons?.length > 0
    } else if (user.context === SCHOOL_CONTEXT) {
      filter = filteredSchoolSections?.find(section => section?.id === optionToFilter.id)
    }

    return filter
  }, [user.context, filteredSchoolSections])

  const contextualColumns = useMemo(() =>
    visibility !== VisibilityValues.INTERNAL
      ? EXTERNAL_VISIBILITY_COLUMN_TITLES[user.context]
      : []
  , [user.context, visibility])

  const contactPersons = useMemo(() => data.map(contact => {
    const contextKeys = {}
    const contactPerson = {
      firstname: contact.firstname,
      lastname: contact.lastname,
      email: contact.email,
      phone_number: contact.phone
    }

    if (visibility !== VisibilityValues.INTERNAL) {
      contactPerson.role = t(contact.roles)

      if (user.context === INSTITUTION_CONTEXT) {
        contextKeys.entityName = contact?.contactPersonSections_school_name
        contextKeys.nestedEntityName = contact?.contactPersonSections_sectionToYear_sectionAbbreviation + ` (${contact?.contactPersonSections_sectionToYear_schoolYearName})`
      } else if (user.context === SCHOOL_CONTEXT) {
        contextKeys.entityName = contact?.contactPersonSectors_institution_acronym
        contextKeys.nestedEntityName = contact?.contactPersonSectors_name
      }

      contactPerson[contextualColumns[0]] = contextKeys.entityName
      contactPerson[contextualColumns[1]] = contextKeys.nestedEntityName
    } else {
      if (user.context === INSTITUTION_CONTEXT) {
        contactPerson.nestedEntities = contact.contactPersonSectors
      } else if (user.context === SCHOOL_CONTEXT) {
        contactPerson.nestedEntities = contact.contactPersonSections
      }
    }

    return contactPerson
  }), [t, data, visibility])

  const formatColumns = useCallback((columns, translateKey) => columns.map(title => ({
    title: toFirstUpperCase(t(translateKey + title)),
    key: title,
    orderBy: title,
    style: { maxWidth: '200px' }
  })), [t])

  const columns = useMemo(() => {
    const commonColumns = formatColumns(CONTEXT_COMMON_COLUMN_TITLES[visibility], TRANSLATE_PREFIX_USER)
    const contextColumns = formatColumns(contextualColumns, TRANSLATE_PREFIX_CONTEXT)

    return contextColumns.concat(commonColumns)
  }, [t, user.context, visibility])

  const tableActions = useMemo(() => {
    if (visibility !== VisibilityValues.INTERNAL) return []

    let contextIcon
    let contextTitle

    if (user.context === INSTITUTION_CONTEXT) {
      contextIcon = faStethoscope
      contextTitle = toFirstUpperCase(t(TRANSLATE_NESTED[user.context]))
    } else if (user.context === SCHOOL_CONTEXT) {
      contextIcon = faGraduationCap
      contextTitle = toFirstUpperCase(t(TRANSLATE_NESTED[user.context]))
    }

    const selectContactAction = new Action({
      icon: contextIcon,
      title: t('contact_persons_view.assignments_button_title'),
      key: contextTitle,
      onClick: setSelectedContact
    })

    return [selectContactAction]
  }, [user.context])

  const tableFilters = useMemo(() => {
    if (visibility !== VisibilityValues.INTERNAL) return []

    const { context } = user

    const contextValues = {
      [INSTITUTION_CONTEXT]: {
        icon: faHospital,
        label: t(TRANSLATE_NESTED[context]),
        component: SectorsSelector
      },
      [SCHOOL_CONTEXT]: {
        icon: faSchool,
        label: t(TRANSLATE_NESTED[context]),
        component: SchoolSectionsSelector
      }
    }

    const Component = contextValues[context].component

    return [
      new Filter({
        key: contextValues[context].label,
        label: t('misc.filters'),
        component: () => {
          return (
            <ComponentWithIconLabel icon={contextValues[context].icon} label={t(contextValues[context].label)}>
              <Component
                style={{ width: '300px' }}
                values={options}
                filter={optionsFilter}
                onChange={onOptionsChange}
              />
            </ComponentWithIconLabel>
          )
        }
      })
    ]
  }, [user.context, visibility, options, optionsFilter, onOptionsChange])

  return (
    <>
      <DataTable
        columns={columns}
        data={contactPersons}
        metadata={metadata}
        loading={loading}
        parameters={tableParameters}
        onParametersChange={onTableParametersChange}
        actions={tableActions}
        filters={tableFilters}
        formatColumns={formatColumns}
      />
      <ContactPersonAssignments
        selectedContact={selectedContact}
        onClose={() => setSelectedContact(null)}
        formatColumns={formatColumns}
      />
    </>
  )
}

export default connect(mapStateToProps)(ContactPersonsTable)
