import apiClient from "./apiClient"

const fetchInsitutionContactPersons = (user, institutionId, queryParams) => apiClient
  .get(user, `api/institutions/${institutionId}/contact-persons`, queryParams)

const fetchSchoolContactPersons = (user, schoolId, queryParams) => apiClient
  .get(user, `api/schools/${schoolId}/contact-persons`, queryParams)

const fetchSchoolSectionsLinkedToContactPersons = (user, schoolId) => apiClient
  .get(user, `api/schools/${schoolId}/school-sections?withContactPerson=1`)

const updateContactPerson = (userToUpdate, user) => {
  const body = {
    email: userToUpdate.email,
    lastname: userToUpdate.lastname,
    firstname: userToUpdate.firstname,
    phone: userToUpdate.phone
  }

  return apiClient.post(
    `user/modify/${userToUpdate.id}`,
    body,
    user
  )
}

export {
  fetchInsitutionContactPersons,
  fetchSchoolContactPersons,
  fetchSchoolSectionsLinkedToContactPersons,
  updateContactPerson
}
