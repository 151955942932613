import React, { Suspense, useContext, useMemo, useState } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import { ROLE_UNKNOWN, ROLE_VALIDATOR } from '../utils/constants'
import routes from '../routes'
import { getUser } from '../reducers/UserReducer'
import ShiftPresetsManager from '../Views/shared/ShiftsPresetsManagerView/ShiftPresetsManager'
import ChangeLogView from '../Views/shared/ChangeLogView'
import TagsManagementView from '../Views/shared/TagsManagementView'
import ShiftsManagerView from '../Views/institution/InstitutionShiftManager'
import { isArray } from 'lodash'
import { connect } from 'react-redux'
import InstitutionEvaluationSummaryView from '../Views/institution/InstitutionEvaluationSummaryView'
import SchoolModulesSettings from '../Views/institution/SchoolModulesSettings'
import ReportingView from '../Views/shared/ReportingView'
import InstitutionQuotasView from '../Views/institution/InstitutionQuotasView'
import { GlobalContext } from '../Providers/GlobalProvider'
import InstitutionSettingsView from '../Views/institution/Settings/InstitutionSettingsView'
import InstitutionProvider from '../Providers/InstitutionProvider'
import SchoolsView from '../Views/institution/SchoolsView'
import StudentsView from '../Views/institution/StudentsView'
import InternshipsView from '../Views/institution/InternshipsView/InternshipsView'
import SectorsView from '../Views/institution/SectorsView/SectorsView'
import ValidatorSectorsView from '../Views/school/validator/SectorsView'
import InternshipMessageProvider from '../Providers/InternshipMessageProvider'
import ContactPersonsTabsView from '../Views/shared/ContactPersons/ContactPersonsTabsView'

const mapStateToProps = state => ({ user: getUser(state.getUser) })

const InstitutionRouter = ({ history, moduleOption, user }) => {
  const { currentDate, setCurrentDate } = useContext(GlobalContext)

  const [selectedSector, setSelectedSector] = useState(null)

  const currentRole = useMemo(() => isArray(user.roles) ? user.roles[0] : ROLE_UNKNOWN, [user.roles])

  const goToShiftPresets = sector => {
    setSelectedSector(sector || null)
    history.push(routes.PRESETS_MANAGER)
  }

  return (
    <InstitutionProvider>
      <InternshipMessageProvider>
        <Switch>
          <Route path={routes.INTERNSHIPS}>
            <InternshipsView loadShiftsManager={setCurrentDate} />
          </Route>
          <Route path={routes.STUDENTS}>
            <StudentsView />
          </Route>
          <Route path={routes.SETTINGS}>
            <InstitutionSettingsView />
          </Route>
          <Route path={routes.QUOTAS}>
            <InstitutionQuotasView />
          </Route>
          <Route path={routes.BADGES}>
            <ReportingView />
          </Route>
          <Route path={routes.MODULES_SETTINGS}>
            <SchoolModulesSettings moduleOption={moduleOption} />
          </Route>
          <Route path={routes.SHIFTS_MANAGER}>
            <ShiftsManagerView
              currentDate={currentDate}
              displayManagePresets={goToShiftPresets}
            />
          </Route>
          <Route path={routes.SCHOOLS}>
            <SchoolsView />
          </Route>
          {currentRole !== ROLE_VALIDATOR && (
            <Route path={routes.INSTITUTION_SECTORS}>
              <SectorsView displayManagePresets={goToShiftPresets} />
            </Route>
          )}
          {currentRole === ROLE_VALIDATOR && (
            <Route path={routes.INSTITUTION_SECTORS}>
              <ValidatorSectorsView />
            </Route>
          )}
          <Route path={routes.PRESETS_MANAGER}>
            <ShiftPresetsManager
              sector={selectedSector}
            />
          </Route>
          <Route path={routes.TAGS_MANAGEMENT}>
            <TagsManagementView />
          </Route>
          <Route path={routes.CHANGELOG}>
            <ChangeLogView />
          </Route>
          {currentRole !== ROLE_VALIDATOR && (
            <Route path={routes.CONTACT_PERSONS}>
              <ContactPersonsTabsView />
            </Route>
          )}
          <Route path={routes.STUDENTS_EVALUATION}>
            <Suspense fallback={<div>Loading...</div>}>
              <InstitutionEvaluationSummaryView />
            </Suspense>
          </Route>
        </Switch>
      </InternshipMessageProvider>
    </InstitutionProvider>
  )
}

export default withRouter(connect(mapStateToProps)(InstitutionRouter))
