import React, { useContext, useMemo } from 'react'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import EntitiesSelector from './EntitiesSelector'

const InstitutionsSelector = ({ values, style = {}, onChange }) => {
  const { institutions } = useContext(GlobalContext)

  const institutionsOptions = useMemo(() => institutions.map(institution => ({
    label: institution.acronym ?? institution.name,
    value: institution.id,
    tooltip: institution.name
  })), [institutions])

  return (
    <EntitiesSelector
      onChange={onChange}
      entityOptions={institutionsOptions}
      values={values}
      style={{ ...style }}
    />
  )
}

export default InstitutionsSelector
