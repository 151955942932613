import { connect, mapDispatchToProps } from '../../../reducers/Dispatchers'
import { getTranslate } from 'react-localize-redux'

import { Tabs } from 'antd'
import { faAddressBook, faPeopleArrows } from '@fortawesome/pro-solid-svg-icons'
import IconLabel from '../../../Components/icons/IconLabel'
import ContactPersonsView from './ContactPersonsView'
import VisibilityValues from './VisibilityValues'

const { TabPane } = Tabs

const mapStateToProps = state => ({
  t: getTranslate(state.locale)
})

const ContactPersonsTabsView = ({ t, defaultActiveKey = '1' }) => {
  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      animated={false}
      onChange={() => null}
    >
      <TabPane
        key='1'
        tab={
          <IconLabel
            icon={faPeopleArrows}
            text={t('contact_persons_view.tabs_title.my_contact_persons')}
            padding=''
            fontSize='small'
          />
        }
      >
        <ContactPersonsView visibility={VisibilityValues.INTERNAL} />
      </TabPane>
      <TabPane
        key='2'
        tab={
          <IconLabel
            icon={faAddressBook}
            text={t('contact_persons_view.tabs_title.partners_contact_persons')}
            padding=''
            fontSize='small'
          />
        }
      >
        <ContactPersonsView visibility={VisibilityValues.EXTERNAL} />
      </TabPane>
    </Tabs>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPersonsTabsView)
