import React from 'react'

import IconLabel from './IconLabel'

const ComponentWithIconLabel = ({ children, icon, label, ...props }) => {
  return (
    <>
      <IconLabel
        padding={0}
        icon={icon}
        text={label}
      />
      {React.cloneElement(children, props)}
    </>
  )
}

export default ComponentWithIconLabel
