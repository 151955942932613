import React, { useState, useEffect } from 'react'
import { Drawer } from 'antd'
import SmartTable, { DATA_TYPE_STRING } from '../shared/SmartTable'
import { connect } from '../../reducers/Dispatchers'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { requestWithPromise } from '../../utils'

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  getUser: getUser(state.getUser)
})

const ContactPersonSectors = props => {
  const [contactPersonsSector, setContactPersonsSector] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.sector !== null && props.getUser) {
      requestWithPromise(`/api/sectors/${props.sector.id}/contact-persons`, 'GET', null, props.getUser)
        .then((jsonData) => {
          setContactPersonsSector(jsonData.data)
          setLoading(false)
        })
    } else {
      setLoading(true)
    }
  }, [props.sector, props.getUser])

  const COLUMNS = [
    {
      type: DATA_TYPE_STRING,
      name: props.t('lastname'),
      key: 'firstname'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('firstname'),
      key: 'lastname'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Email'),
      key: 'email'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Phone number'),
      key: 'phone'
    }
  ]

  return (
    <Drawer
      title={props.sector !== null ? props.t('Contact persons of') + ' ' + props.sector.name : '?'}
      width='640px'
      onClose={props.onClose}
      visible={props.sector !== null}
    >
      <SmartTable
        columns={COLUMNS}
        data={contactPersonsSector}
        loading={loading}
        noDataText={props.t('Without person contact')}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps)(ContactPersonSectors)
