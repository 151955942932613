import { requestWithPromise } from ".."
import { makeQueryString, HOST_NAME, GET, POST, PATCH, DELETE } from "../apiHelper"

const apiClient = Object.freeze({
  /** @param {object} user @param {string} url @param {object} queryParams */
  get: (user, url, queryParams) => requestWithPromise(HOST_NAME + url + makeQueryString(queryParams), GET, null, user),
  /** @param {object} user @param {string} url @param {object} body */
  post: (user, url, body) => requestWithPromise(HOST_NAME + url, POST, body, user),
  /** @param {object} user @param {string} url @param {object} body */
  patch: (user, url, body) => requestWithPromise(HOST_NAME + url, PATCH, body, user),
  /** @param {object} user @param {string} url @param {object} queryParams */
  delete: (user, url, queryParams) => requestWithPromise(HOST_NAME + url + makeQueryString(queryParams), DELETE, null, user),
})

export default apiClient