import React, { useContext, useMemo } from 'react'
import { InstitutionContext } from '../../../Providers/InstitutionProvider'
import EntitiesSelector from './EntitiesSelector'

const SchoolsSelector = ({ values, style = {}, onChange }) => {
  const { schools } = useContext(InstitutionContext)

  const schoolsOptions = useMemo(() => schools.map(school => ({
    label: school.abbreviation ?? school.name,
    value: school.id,
    tooltip: school.name
  })), [schools])

  return (
    <EntitiesSelector
      onChange={onChange}
      entityOptions={schoolsOptions}
      values={values}
      style={{ ...style }}
    />
  )
}

export default SchoolsSelector
